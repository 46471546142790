/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

window.Rails = require("@rails/ujs")

require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("trix")
require("@rails/actiontext")
require("chartkick").use(require("highcharts"))

import LocalTime from "local-time"
LocalTime.start()

import "stylesheets/application"

import "controllers"

import "src/confirm"
import "src/direct_uploads"
import "src/forms"
import "src/timezone"

import tippy from 'tippy.js'
document.addEventListener("turbolinks:load", () => {
  tippy(document.querySelectorAll('[data-tippy-content]'))
})

Rails.start()

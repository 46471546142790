import CheckboxSelectAll from "stimulus-checkbox-select-all"

export default class extends CheckboxSelectAll {

    flip() {
        let bulk = document.getElementById("bulk_actions")
        if (this.checked.length == 0) {
            bulk.style.display = 'none'
        }
        else {
            bulk.style.display = 'block'
        }


    }

    flop() {
        let bulk = document.getElementById("bulk_actions")
        if (this.checked.length == 0) {
            bulk.style.display = 'block'
        }
        else {
            bulk.style.display = 'none'
        }


    }

}